import React, { useState } from 'react'
import "./nav.css"
import { GoPlus } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../backend/Firebase';

import { PiRocket } from "react-icons/pi";
import { toast } from 'react-toastify';
import { FaWhatsapp } from "react-icons/fa";



const Nav = (props) => {
  const [navex, setNavex] = useState(false);
  const [user, setUser] = useState(null);
  const [showHiddenDiv, setShowHiddenDiv] = useState(false)
  const [showHiddenDiv2, setShowHiddenDiv2] = useState(false)
  const [showHiddenDiv3, setShowHiddenDiv3] = useState(false)

  const navigate = useNavigate();
  const firebase = useFirebase();
  const [login, setLogin] = useState(false);
  const ismobile = window.innerWidth >= 768 ? true : false;

  const handel = () => {
    setNavex(!navex);

  }
  const whatsappURL ="https://chat.whatsapp.com/JHcU8XL2zUx49uYhcbRjl0"

  // useEffect(() => {
  //   // Subscribe to changes in the Firebase context
  //   setLogin(firebase.login)

  // }, [firebase.login]);

  // useEffect(() => {
  //   setTimeout(()=>{
  //     setNavex(true);

  //   },700)

  // }, []);

  const handleScrollToEvent = () => {
    const eventComponent = document.getElementById('contest-wrap');
    if (eventComponent) {
      eventComponent.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const handleScrollToEvent2 = () => {
    const eventComponent = document.getElementById('sponnser');
    if (eventComponent) {
      eventComponent.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const handleScrollToEvent3 = () => {
    const eventComponent = document.getElementById('sponnser');
    if (eventComponent) {
      eventComponent.scrollIntoView({ behavior: 'smooth' });
    }
  }


  const handleMouseOver = () => {
    setShowHiddenDiv(true);
  }

  const handleMouseOut = () => {
    setShowHiddenDiv(false);
  }
  const handleMouseOver2 = () => {
    setShowHiddenDiv2(true);
  }
  
  const handleMouseOut2 = () => {
    setShowHiddenDiv2(false);
  }
  const handleMouseOver3 = () => {
    setShowHiddenDiv3(true);
  }
  
  const handleMouseOut3 = () => {
    setShowHiddenDiv3(false);
  }
  // useEffect(()=>{
  //   if(setShowHiddenDiv){

  //     setShowHiddenDiv(false);
  //   }
  //   if(setShowHiddenDiv2){

  //     setShowHiddenDiv2(false);
  //   }


  // },[showHiddenDiv,showHiddenDiv2])







  console.log("firebase.login", firebase.user)


  const StyleL = {
    transform: navex ? 'translateY(00px) ' : 'translateY(-30vh) ',

    transition: 'transform 0.4s ease-out',

  };
  const StyleP = {
    transform: !navex ? 'rotate(0)' : 'rotate(180deg)',
    transition: 'transform 0.3s ease-out',

  };
  return (
    <div className='nav-wrap'>
      <div className='nav-con'>
        <div className='nav-logo'>
        <a className='axi' href='https://iitmjanakpuriaiec.com/' target='_blank' >AIEC</a> x <a className='axi' href='https://iitmjanakpuri.com/' target='_blank' >IITM</a>


        </div>
        <a className='nav-logo nav-chat' target="_blank" onClick={()=>navigate("./SponsorUs")} >SPONSORS US</a>
        <div className='nav-icon-con'>
          <div  className='n-i-c' >
          <GoPlus className='nav-plus' onClick={handel} style={StyleP} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
            {showHiddenDiv && <p className='nav-hov'>Navbar</p>}

          </div>
          <div  className='n-i-c'>

            <PiRocket className='nav-pro' onClick={() => props.setIsRight(true)} onMouseOver={handleMouseOver2} onMouseOut={handleMouseOut2} />
            {showHiddenDiv2 && <p className='nav-hov'>Profile</p>}
          </div>
          <div  className='n-i-c'>
            <a target="_blank"  href={whatsappURL}>


            <FaWhatsapp  className='nav-pro'  onMouseOver={handleMouseOver3} onMouseOut={handleMouseOut3} />
            {showHiddenDiv3 && <p className='nav-hov'>WhatsApp</p>}
            </a>
          </div>

        </div>
      </div>

      <div className='nav-ex' style={StyleL}>
        <div className='nav-ex-con nav-ex' id='nav-ex-con'>

          <div className='ex1b' onClick={() => navigate("./admin")}>Admin.</div>
          <div className='ex1' onClick={handleScrollToEvent2}>Sponsor</div>
          <div className='ex1' onClick={handleScrollToEvent} >Events</div>

          <div className='ex1' onClick={() => navigate("./guest")}>Guest</div>
          <div className='ex1bo'></div>
          <a className='ex1w nav-chat2' href={whatsappURL} target="_blank" > <p  >WhatsApp</p></a>
        </div>


        <div className='ex'>
          {
            !firebase.user ? (
              <div className='ex-card' onClick={() => navigate("/")}>

                <p>L</p>
                <p>O</p>
                <p>G</p>
                <p>I</p>
                <p>N</p>


              </div>
            ) : (

              <div className='ex-card' onClick={() => {
                firebase.logOut()
                setUser(null)
                setLogin(true)
                setTimeout(() => {
                  navigate("/")

                }, 700);

              }}>

                <p>L</p>
                <p>O</p>
                <p>G</p>
                <p>O</p>
                <p>U</p>
                <p>T</p>


              </div>
            )
          }



        </div>
      </div>







    </div>

  )
}

export default Nav