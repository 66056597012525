import React,{useState , useEffect} from 'react'
import ReactPlayer from 'react-player'
import introM from "./introM.mp4"
import introL from "./introL.mp4"
import "./intro.css"

const Intro = () => {
  const videoSource = window.innerWidth <= 768 ? introL : introM;

   
  const [shouldApplyStyle, setShouldApplyStyle] = useState(false);
  const [scrollDisabled, setScrollDisabled] = useState(false);
  useEffect(() => {
    if (!scrollDisabled) {
      window.scrollTo(0, 0);
    }
  }, [scrollDisabled]);


  useEffect(() => {
      // After 2 seconds, set the state to apply the style
      disableScroll();
      const timeoutId = setTimeout(() => {
        enableScroll();
          setShouldApplyStyle(true);
          
      }, 7000);

      // Cleanup the timeout on component unmount
      return () => clearTimeout(timeoutId);
  }, []);
  const enableScroll = () => {
    setScrollDisabled(false);
    document.body.style.overflow = 'visible';
  };

  const disableScroll = () => {
    setScrollDisabled(true);
    document.body.style.overflow = 'hidden';
  };

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures that this effect runs only once after the initial render

  const StyleL = {
      transform: shouldApplyStyle ? 'translateY(-900px)' : '',
      transition: 'transform 1.5s ease-in-out',
      marginTop:"-50px"
  };
  return (
    <div className="rp">
        <ReactPlayer
       
        url={videoSource}
        muted={true}
        playing={true}
        controls={false}
      
        height="100%"
        width="100%"
        loop={false}
        style={StyleL}
    
        >
        

        </ReactPlayer>
    </div>
  )
}

export default Intro