import React from 'react';
import './TermsAndConditions.css'; // Import your CSS file for styling

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions-container">
      <h2>Terms and Conditions</h2>
      <p>
        Please read these terms and conditions carefully before using our
        website and participating in our events.
      </p>
      <h3>1. Acceptance of Terms</h3>
      <p>
        By accessing or using our website and participating in our events, you
        agree to be bound by these terms and conditions. If you do not agree
        with any part of these terms, you may not use our website or
        participate in our events.
      </p>
      <h3>2. Registration and Account</h3>
      <p>
        You may need to register for an account to access certain features of
        our website and participate in our events. You are responsible for
        maintaining the confidentiality of your account and password and for
        restricting access to your account.
      </p>
      <h3>3. Event Participation</h3>
      <p>
        Participation in events hosted by AIEC may
        require registration and/or payment. By registering for an event, you
        agree to abide by any rules and regulations set forth by AIEC.
      </p>
      <h3>4. Intellectual Property</h3>
      <p>
        All content on our website, including but not limited to text, graphics,
        logos, and images, is the property of AIEC and is
        protected by intellectual property laws.
      </p>
      <h3>5. Limitation of Liability</h3>
      <p>
        AIEC shall not be liable for any direct,
        indirect, incidental, special, or consequential damages arising out of
        or in any way connected with the use of our website or participation in
        our events.
      </p>
      <h3>6. Changes to Terms and Conditions</h3>
      <p>
        AIEC reserves the right to update or change
        these terms and conditions at any time without prior notice. Any
        changes will be effective immediately upon posting on this page.
      </p>
      <h3>7. Contact Us</h3>
      <p>
        If you have any questions or concerns about these terms and conditions,
        please contact us at a.i.experiencecenter.iitm@gmail.com.
      </p>
    </div>
  );
};

export default TermsAndConditions;
