import React from 'react'
import "./sponser.css"
import eth from "./732.png"
import poly from "./poly.png"
import cb from "./CB.png"
import IN from "./IN.png"
import land from "./landmark.png"
import shape from "./shape.png"
import vr from "./ver.png"

const Sponsor = () => {
    return (
        <div className='sp-wrap' id='sponnser'>

            <h2 className='sp-head ev-head'>OUR SPONSORS</h2>
            <div className='sp-con'>


                <div className='sp-card'>
                    <div className='sp-img-con'>
                        <img className='sp-img' src={poly}></img>
                    </div>
                    <div className='sp-bottom'>
                        <a href='https://polygon.technology/' target='_blank' className='sp-name'>
                            polygon
                        </a>

                    </div>

                </div>

                <div className='sp-card'>
                    <div className='sp-img-con'>
                        <img className='sp-img' src={eth}></img>
                    </div>
                    <div className='sp-bottom'>
                        <a href='https://ethindia.co/' target='_blank' className='sp-name'>
                            ETHIndia
                        </a>

                    </div>

                </div>

                {/* <div className='sp-card'>
                    <div className='sp-img-con'>
                        <img className='sp-img' src={cb}></img>
                    </div>
                    <div className='sp-bottom'>
                        <a href='https://codingblocks.com/' target='_blank' className='sp-name'>
                            CODING BLOCKS
                        </a>

                    </div>

                </div> */}
                <div className='sp-card'>
                    <div className='sp-img-con'>
                        <img className='sp-img' src={vr}></img>
                    </div>
                    <div className='sp-bottom'>
                        <a href='https://www.verbwire.com//' target='_blank' className='sp-name'>
                        Verbwire
                      
                        </a>

                    </div>

                </div>
                <div className='sp-card'>
                    <div className='sp-img-con'>
                        <img className='sp-img' src={IN}></img>
                    </div>
                    <div className='sp-bottom'>
                        <a href='https://www.internnexus.com/' target='_blank' className='sp-name'>
                        InternNexus
                        </a>

                    </div>

                </div>
                <div className='sp-card'>
                    <div className='sp-img-con'>
                        <img className='sp-img' src={land}></img>
                    </div>
                    <div className='sp-bottom'>
                        <a href='https://www.landmarkinstitute.com/' target='_blank' className='sp-name'>
                        LANDMARK
                        </a>

                    </div>

                </div>
                <div className='sp-card'>
                    <div className='sp-img-con'>
                        <img className='sp-img' src={shape}></img>
                    </div>
                    <div className='sp-bottom'>
                        <a href='https://shapemyskills.in/' target='_blank' className='sp-name'>
                        ShapeMySkills
                        </a>

                    </div>

                </div>

            </div>


        </div>
    )
}

export default Sponsor