import React, { useEffect,useState } from 'react'
import "./glitch.css"

const Glitch = (props) => {

    const [shouldApplyStyle, setShouldApplyStyle] = useState(false);
    const ismobile = window.innerWidth >= 768 ? false : true;

    useEffect(() => {
        // After 2 seconds, set the state to apply the style
        const timeoutId = setTimeout(() => {
            setShouldApplyStyle(true);
        }, 600);

        // Cleanup the timeout on component unmount
        return () => clearTimeout(timeoutId);
    }, []); // The empty dependency array ensures that this effect runs only once after the initial render

    const StyleL = {

        transform: shouldApplyStyle ? 'translateX(100%)' : 'translateX(0)',
        transition: 'transform 1.2s ease-in-out'
    };
    const StyleM = {
      
    
        transform: shouldApplyStyle ? 'translateX(5%)' : 'translateX(0)',
        transition: 'transform 1.2s ease-in-out',
        
    };

    return (
        <div className='gl-con' style={ismobile?(StyleM):(StyleL)}>



            <div class="hero-container">
                <div class="environment"></div>
                <h2 class="hero glitch layers" data-text="AIEC CEll"><span>
                  {props.txt}</span></h2>
            </div>

            

        </div>
    )
}

export default Glitch