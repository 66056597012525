import React, { useState, useEffect } from 'react'
import "./GetContestDetails.css"

import { useFirebase } from '../../backend/Firebase';
import { toast } from 'react-toastify';

const GetContestDetails = (props) => {
  const [teamType, setTeamType] = useState("");
  const contestName = props.name;
  const firebase = useFirebase();

  const currentDate = new Date();


  // Parse the JSON string back to an object

  const [txt, setTxt] = useState(false);
  const [formData, setFormData] = useState({

    member1: '',
    member2: '',
    member3: '',
    member4: '',
    eteamName: "",
    fGame: "",

    collegeName: '',
    phone: '',
    zcurrentDate: currentDate.toISOString(),
    contestName: contestName,
    price: props.price,
    zuserId: props.LuserID,
    auserName: props.LuserName || "NOT avaliable",
    auserEmail: props.LuserEmail,
  });



  const [isContestNamePresent, setIsContestNamePresent] = useState(false);


  const handleTeamTypeChange = (e) => {
    const newTeamType = e.target.value;
    setTeamType(newTeamType);
    console.log("game is", newTeamType)
    setFormData(prevData => ({
      ...prevData,
      fGame: newTeamType, // Store the selected game type in the fGame field of formData
    }));
  };






  // Calculate the price based on the number of team members
  const calculatePrice = () => {
    const basePrice = parseFloat(props.price); // Convert price to float
    const numberOfMembers = Object.values(formData)
      .slice(0, 4) // Consider only member fields
      .filter(value => value.trim() !== '') // Filter out empty values
      .length;

    // Calculate the adjusted price
    const adjustedPrice = basePrice * Math.max(1, numberOfMembers);

    return adjustedPrice.toFixed(2); // Convert to string with 2 decimal places
  };


  // const handleTeamTypeChange = (e) => {
  //   const newTeamType = e.target.value;
  //   const updatedTeamName = `${formData.eteamName.split(' ')[0]} ${newTeamType}`; // Keep the first part of the team name and append the new game type
  //   setTeamType(newTeamType);
  //   setFormData(prevData => ({
  //     ...prevData,
  //     eteamName: updatedTeamName,
  //   }));
  // };

  // Update the price in the formData whenever there's a change in the form fields
  useEffect(() => {
    setFormData(prevData => ({
      ...prevData,
      price: calculatePrice()
    }));
  }, [formData.member1, formData.member2, formData.member3, formData.member4]);



  useEffect(() => {
    const checkContestName = firebase.buyContest.some((item) => Object.keys(item)[0] === contestName);
    setIsContestNamePresent(checkContestName);
  }, [firebase.buyContest, contestName]);
  useEffect(() => {
    setTimeout(() => {
      if (txt) {

        setTxt(false);
      }

    }, 3000)

  }, [txt]);



  useEffect(() => {


    // Reset team member fields
    setFormData(prevData => ({
      ...prevData,
      member2: '',
      member3: '',
      member4: ''
    }));

  }, [teamType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleToggle = (e) => {
    e.preventDefault();

    if (isContestNamePresent) {
      // If present, remove the contest data
      const indexToRemove = firebase.buyContest.findIndex((item) => Object.keys(item)[0] === contestName);

      if (indexToRemove !== -1) {
        const updatedBuyContest = [...firebase.buyContest.slice(0, indexToRemove), ...firebase.buyContest.slice(indexToRemove + 1)];
        firebase.setBuyContest(updatedBuyContest);
        console.log('Contest data removed:', contestName);
        toast.success("Removed from cart");
        setIsContestNamePresent(false);
        props.setIsAdded(false);
      } else {
        console.log('Contest data not present, not removing.');
      }
    } else {
      // If not present, add the contest data
      const areRequiredFieldsFilled = props.id === 7 ?
        (
          formData.eteamName.trim() !== '' &&
          formData.member1.trim() !== '' &&
          formData.collegeName.trim() !== '' &&
          formData.phone.trim() !== '' &&
          formData.fGame.trim() !== ''
        ) :
        (
          formData.eteamName.trim() !== '' &&
          formData.member1.trim() !== '' &&
          formData.collegeName.trim() !== '' &&
          formData.phone.trim() !== ''
        );


      // if (props.id === 7 && teamType === "") {
      //   console.log('Please select a game type.');
      //   setTxt(true);
      //   return; // Prevent form submission
      // }

      if (!areRequiredFieldsFilled) {
        console.log('Please fill all required fields before adding.');
        setTxt(true);
        return;
      }

      const contestDataObject = {
        [contestName]: formData,
      };

      const updatedBuyContest = [...firebase.buyContest, contestDataObject];
      firebase.setBuyContest(updatedBuyContest);
      console.log('Contest data added:', contestDataObject);
      toast.success("Added in cart");
      setIsContestNamePresent(true);
      props.setIsAdded(true);
      setTimeout(() => {

        props.setIsopen(false);
      }, 200)

    }
    // console.log(firebase.buyContest[0],"ghvcjvhde")
  };

  const [hidden, setHidden] = useState(false);

  useEffect(() => {

    if (props.id === 1 || props.id === 8 || props.id === 6 || props.id === 3) {
      setHidden(true)
    }


  }, [])

  return (
    <div className='cd-wrap'>
      <div className='cd-con'>

        <form className='cd-form'>
          <h2 className='cd-h'>{props.name}</h2>
          <div className='cd-tn-s'>


            <label className='cd-label'>
              Team Name*


              <input className='cd-in' type="text" name="eteamName" value={formData.eteamName} onChange={handleChange} required />
            </label>
            {props.id === 7 && (
              <label className='cd-label'>
                Select Game*
                <select value={teamType} onChange={handleTeamTypeChange} className='cd-s'>
                  <option value="">Select Game</option>
                  <option value="bgmi">BGMI</option>
                  <option value="tekken 7">Tekken</option>
                </select>
              </label>
            )}
          </div>
          <div className='cd-label-con'>
            <label className='cd-label'>
              Team Member 1*
              <input className='cd-in' type="text" name="member1" value={formData.member1} onChange={handleChange} required />
            </label>

            {
              (props.id === 7 && teamType === "tekken 7") ? (""
              ) : (<label className='cd-label' style={{ opacity: hidden ? 0.2 : 1 }}>
                Team Member 2
                <input className='cd-in' type="text" name="member2" value={formData.member2} onChange={handleChange} required disabled={hidden} />
              </label>)
            }


            {props.id !== 9 && (
              <>


                {
                  (props.id === 7 && teamType === "tekken 7") ? (""
                  ) : (<label className='cd-label' style={{ opacity: hidden ? 0.2 : 1 }}>
                    Team Member 3
                    <input className='cd-in' type="text" name="member3" value={formData.member3} onChange={handleChange} disabled={hidden} />
                  </label>)
                }



                {
                  (props.id === 7 && teamType === "tekken 7") ? (""
                  ) : (<label className='cd-label' style={{ opacity: hidden ? 0.2 : 1 }}>
                    Team Member 4
                    <input className='cd-in' type="text" name="member4" value={formData.member4} onChange={handleChange} disabled={hidden} />
                  </label>)
                }


                {/*               
                <label className='cd-label' style={{ opacity: hidden ? 0.2 : 1 }}>
                  Team Member 3
                  <input className='cd-in' type="text" name="member3" value={formData.member3} onChange={handleChange} disabled={hidden} />
                </label>

                <label className='cd-label' style={{ opacity: hidden ? 0.2 : 1 }}>
                  Team Member 4
                  <input className='cd-in' type="text" name="member4" value={formData.member4} onChange={handleChange} disabled={hidden} />
                </label> */}
              </>
            )}
          </div>


          <div className='cd-con-last-con'>
            <div className='cd-con-last'>

              <label className='cd-label'>
                College Name*
                <input className='cd-in' type="text" name="collegeName" value={formData.collegeName} onChange={handleChange} required />
              </label>

              <label className='cd-label'>
                Phone Number*
                <input className='cd-in' type="text" name="phone" value={formData.phone} onChange={handleChange} required />
              </label>

            </div>
            <div className='cd-botm'>

              <p className='cd-price'>*Registration fee  Rs {props.price} /-
                <p>(Per Person)</p></p>
              {
                txt ? (<p className='cd-price' >*Please fill required fields</p>) : ("")
              }
            </div>



          </div>









        </form>
        {
            props.id === 7?(<p className='event_closed'>Entry is Closed (SOLD OUT)</p>):("")
          }

        <div class="ios-btn-con">





          <button className="ios-btn2" onClick={(e) => {
            e.preventDefault();
            setTimeout(() => {
              props.setIsopen(false)
            }, 300)

          }} > Close</button>


         



          {
            props.id === 7 ? (""): (  <button className="ios-btn" onClick={handleToggle}>
            {isContestNamePresent ? "Remove" : "Add"}
          </button>)
          }



        





        </div>





      </div>

    </div>
  )
}

export default GetContestDetails