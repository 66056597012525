import React, { useEffect, useState } from 'react';
import { useFirebase } from '../backend/Firebase';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ToastContainer, toast } from 'react-toastify';
import "./admin.css"

const Admin = () => {
  const firebase = useFirebase();
  const [pass, setPass] = useState(false)
  const [key, setKey] = useState("")
  const [buyContestData, setBuyContestData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await firebase.fetchAllBuyContestDocuments();
        console.log("Fetched data:", data); // Check if data is fetched correctly
        setBuyContestData(data);
      } catch (error) {
        console.error('Error fetching BuyContest data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (firebase && firebase.user && firebase.user.email === "vermabhavi7890@gmail.com") {
      setPass(true);
    }
  }, [firebase]);
  
  console.log("buyContestData:", buyContestData); // Check if buyContestData is populated correctly

  const contestArrays = buyContestData.map(obj => obj.data.contests);
  console.log("contestArrays:", contestArrays);

  const flattenedArray = contestArrays.reduce((acc, curr) => acc.concat(curr), []);

  // Function to sort objects by a specific field
  const sortObjectKeys = (obj) => {
    const sortedObj = {};
    Object.keys(obj).sort().forEach(key => {
      if (typeof obj[key] === 'object') {
        sortedObj[key] = sortObjectKeys(obj[key]);
      } else {
        sortedObj[key] = obj[key];
      }
    });
    return sortedObj;
  };

  // Map over the array and sort each object within it
  const sortedArrayOfObjects = flattenedArray.map(obj => sortObjectKeys(obj));

  console.log("finalArrayOfObjects:", sortedArrayOfObjects);

  // useEffect(() => {
  //   // Calculate total amount when sortedArrayOfObjects changes
  //   const calculateTotalAmount = () => {
  //     let total = 0;
  //     sortedArrayOfObjects.forEach(contest => {
  //       // Iterate over each contest object
  //       Object.values(contest).forEach(obj => {
  //         // Access the price field from each contest object
  //         const price = parseFloat(obj['price']);
  //         if (!price) {
  //           total += price;
  //         } else {
  //           console.log('Invalid price:', obj['price']);
  //         }
  //       });
  //     });
  //     console.log('Total amount:', total);
  //     setTotalAmount(total);
  //   };
    
    
  
  //   // Call the calculateTotalAmount function
  //   calculateTotalAmount();
  // }, []);
  
  
  
  


  // Function to export sortedArrayOfObjects to Excel
  const exportToExcel = () => {
    const headers = [
      "Payment ID",
      'Email',
      'Name',
      'College Name',
      'Contest Name',
      'Team Name',
      "Game",
      'Team Member 1',
      'Team Member 2',
      'Team Member 3',
      'Team Member 4',
      'Phone Number',
      'Price',
      'Buy Date',
      'userId',
    ];
    // Check if sortedArrayOfObjects is not empty
    if (sortedArrayOfObjects.length === 0) {
      console.error('Array is empty. Cannot export to Excel.');
      return;
    }
    const dataRows = [];

    // Add headers as the first row
    dataRows.push(headers);

    // Create an array to store column widths
    const columnWidths = [];

    // Iterate over each object in sortedArrayOfObjects
    sortedArrayOfObjects.forEach(obj => {
      // Create an array to store values of the current object
      const rowData = [];

      // Iterate over each key-value pair in the object
      Object.values(obj).forEach(value => {
        // Check if the value is an object
        if (typeof value === 'object') {
          // Iterate over each key-value pair in the nested object
          Object.values(value).forEach(nestedValue => {
            // Push the nested value to the rowData array
            rowData.push(nestedValue);

            // Update column width based on the length of the content
            const contentLength = String(nestedValue).length;
            if (!columnWidths[rowData.length - 1] || columnWidths[rowData.length - 1] < contentLength) {
              columnWidths[rowData.length - 1] = contentLength;
            }
          });
        } else {
          // Push the value to the rowData array
          rowData.push(value);

          // Update column width based on the length of the content
          const contentLength = String(value).length;
          if (!columnWidths[rowData.length - 1] || columnWidths[rowData.length - 1] < contentLength) {
            columnWidths[rowData.length - 1] = contentLength;
          }
        }
      });

      // Push the rowData array to the dataRows array
      dataRows.push(rowData);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(dataRows);
    ws['!cols'] = headers.map(() => ({ width: 30 }));
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelBlob, 'AIEC_CodeSapiens.xlsx');
  };

  return (
    <div>
      {
        pass ? (
          <div className='admin-con'>
            <div className='admin-sub'>
              <p className='ad-txt'> AIEC DATA</p>
              <p className='ad-txt'> ADMIN</p>
              <p className='ad-txt'> {totalAmount}</p>
              <div className="glowing-button2 " id='ad-btn' onClick={exportToExcel} > Export Excel</div>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Payment ID</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Email</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Name</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>College Name</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Contest Name</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Team Name</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Game</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Team Member 1</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Team Member 2</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Team Member 3</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Team Member 4</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Phone Number</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Price</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>Buy Date</TableCell>
                    <TableCell align="center" style={{ border: '1px solid black', fontWeight: 'bold' }}>userId</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedArrayOfObjects.map((obj, index) => (
                    <TableRow key={index}>
                      {Object.entries(obj).map(([key, value]) => (
                        <React.Fragment key={key}>
                          {typeof value === 'object' ? (
                            Object.values(value).map((subValue, subIndex) => (
                              <TableCell key={subIndex} align="center" style={{ border: '1px solid black' }}>
                                {subValue}
                              </TableCell>
                            ))
                          ) : (
                            <TableCell align="center" style={{ border: '1px solid black' }}>{value}</TableCell>
                          )}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div className='pass-con'>
            <div className='pass-subcon'>
              <input className='pass-in' type='password' onChange={(e) => setKey(e.target.value)} placeholder='Enter key'></input>
              <button className="glowing-button2 " id='pay-btn' onClick={() => { toast.error("Wrong key") }}>send</button>
              <h2>If you are not an AIEC authorized person, please leave the page.</h2>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default Admin;
