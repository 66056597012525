
import { Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import { Login } from './pages/Login';
import { Rigster } from './pages/Rigster';

import { useState } from 'react';
import ContestDetail from './pages/ContestDetail';
import Admin from './pages/Admin';

import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import ContactUs from './pages/ContactUs';
import RefundPolicy from './pages/RefundPolicy';
import ShippingPolicy from './pages/ShippingPolicy';
import SponsorUs from './pages/SponsorUs';
import Guest from './comp/guest/Guest';

function App() {
  const [Fplay, setFplay] = useState(true);





  return (
    <Routes  >
      <Route path='/home' element={<HomePage
      Fplay={Fplay}
      setFplay={setFplay}/>}
      />
      

      <Route path='/' element={<Login/>}></Route>
      <Route path='/home/admin' element={<Admin/>}></Route>
      <Route path='/Rig' element={<Rigster/>}></Route>
      <Route path='/home/contestDetails/:id' element={<ContestDetail/>}></Route>
     
      <Route path='/home/PrivacyPolicy' element={<PrivacyPolicy/>}></Route>
      <Route path='/home/TermsAndConditions' element={<TermsAndConditions/>}></Route>
      <Route path='/home/ContactUs' element={<ContactUs/>}></Route>
      <Route path='/home/RefundPolicy' element={<RefundPolicy/>}></Route>
      <Route path='/home/ShippingPolicy' element={<ShippingPolicy/>}></Route>
      <Route path='/home/SponsorUs' element={<SponsorUs/>}></Route>
      <Route path='/home/guest' element={<Guest/>}></Route>


  
   


      
    </Routes>
  );
}

export default App;
