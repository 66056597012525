import React from 'react'
import "./sponserus.css"

const SponsorUs = () => {
  return (
    <div className='spu-con'>
      <iframe src="https://drive.google.com/file/d/1VokyxW-GmtGlGqu38t-27H6fH-c_t63G/preview" width="100%" height="100%"></iframe>

    </div>
  )
}

export default SponsorUs;