import React from 'react'
import ReactPlayer from 'react-player'
import backL from './spaceL.mp4'
import backM from './spaceM.mp4'

import "./intro.css"

const Back = () => {



  const StyleL = {
    
      background: "black",
      position: "fixed"
  };
  const videoSource = window.innerWidth >= 768 ? backL : backM;
  return (
    <ReactPlayer

 
    muted={true}
    playing={true}
    controls={false}
    url={videoSource}

    height="100%"
    width="100%"
    loop={true}
    className="back-vid"
    style={StyleL}
    

/>
  )
}

export default Back