import React, { useState, useEffect } from 'react';
import './ScrollingText.css';
import im from "./contImg.jpg"
import Glitch from './Glitch';

const ScrollingText = ({ text }) => {
  const [position, setPosition] = useState(0);

  const handleScroll = () => {
    // Calculate the position based on the scroll position
    const scrollPosition = window.scrollY;
    setPosition(-scrollPosition * 2);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const ismobile = window.innerWidth >= 768 ? false : true;
  const StyleL = {


    transform: `translateX(${(position * -1.9)}px) translateZ(-900px) scale(1.5)`
};
const StyleM = {
  

  transform: `translateX(${(position * -0.8)-20}px) translateZ(-1200px) scale(1.5)`
    
};





  return (
  
      <div className="scrolling-text-container">
        <div className="scrolling-text" style={ismobile?(StyleM):(StyleL)}>
          <h1 className='ts'>
            <span className='tecno'>

             TECHNO
            </span>
             <br></br>
             <span className='spaines'>

            SAPIENS
             </span>
            </h1>
      
          <p>
            </p>
        </div>

        <div className='glcon'>
        <Glitch txt={"15-16 March"} style={{ position: "absolute",
          
        }}/>
        </div>
    
        <div className='img-con'>
          <img src={im} className='s-img1' style={{ transform: ismobile?`translateY(${position * 2}px) translateZ(-500px)  scale(1)`:`translateY(${position * 1.2}px) translateZ(-500px)  scale(1)`, }}></img> 


          {/* <div  className='s-img2 cust-txt' style={{ transform: ismobile?`translateY(${position * 2.3}px) translateZ(-500px)  scale(1)`:`translateY(${position * 1.4}px) translateZ(-500px)  scale(1)`, }}>
          <p>H</p>
            <p>A</p>
            <p>C</p>
            <p>T</p>
            <p>H</p>
            <p>O</p>
            <p>N</p>
          </div> */}



          <img src={im} className='s-img2' style={{ transform: ismobile?`translateY(${position * 2.3}px) translateZ(-500px)  scale(1)`:`translateY(${position * 1.4}px) translateZ(-500px)  scale(1)`, }}></img>


          {/* <div  className='cust-txt s-img3' style={{ transform: ismobile?`translateY(${position * 1.7}px) translateZ(-500px)  scale(1)`:`translateY(${position * 1.2}px) translateZ(-500px)  scale(1)`, }}>
            <p>D</p>
            <p>S</p>
            <p>A</p>
            </div> */}

          <img src={im} className='s-img3' style={{ transform: ismobile?`translateY(${position * 1.7}px) translateZ(-500px)  scale(1)`:`translateY(${position * 1.2}px) translateZ(-500px)  scale(1)`, }}></img>

        </div>
       
      </div>
    
  );
};

export default ScrollingText;
