import React from 'react'
import ReactPlayer from 'react-player'

const Gimples = () => {
    return (
        <div>
            <ReactPlayer


                muted={false}
                playing={false}
                controls={true}
                url={"https://firebasestorage.googleapis.com/v0/b/aiec-c3a56.appspot.com/o/video%2Fevent%20reel%20landscape.mp4?alt=media&token=7dc3216b-2060-4d28-9cec-528250b8dc47"}

                height="100%"
                width="100%"
                loop={true}
                className="back-vid"
               


            /></div>
    )
}

export default Gimples