import React from 'react'
import "./foot.css"
import { useNavigate } from 'react-router-dom'
import Map from './Map';

const Foot = () => {
    const navigate = useNavigate();
    return (
        <div className='f-wrap'>

            <div className='f-con'>
                <div className='f-rig'>

                    <a href='https://iitmjanakpuriaiec.com/' className='ftext'>Our Main Site</a>
                    <p className='ftext' onClick={() => navigate("./PrivacyPolicy")}>Privacy Policy</p>
                    <p className='ftext' onClick={() => navigate("./TermsAndConditions")}>Terms And Conditions</p>


                </div>
                <div className='f-mid'>
                    <p className='f-axi'>IITM X AIEC</p>
                    <Map></Map>
                 
                 

                      


                </div>
                <div className='f-rig'>


                    <p className='ftext' onClick={() => navigate("./ContactUs")}>Contact Us</p>
                    <p className='ftext' onClick={() => navigate("./RefundPolicy")}>Refund Policy</p>
                    <p className='ftext' onClick={() => navigate("./ShippingPolicy")}>Shipping Policy</p>


                </div>


            </div>

            <a className='bhavi' href='https://www.linkedin.com/in/bhavishy/'>Developed and Designed by Bhavishya Verma</a>



        </div>




    )
}

export default Foot