import React, { useEffect, useState } from 'react'
import "./contcard.css"

import { useNavigate } from 'react-router-dom'
import { useFirebase } from '../../backend/Firebase'
import GetContestDetails from './GetContestDetails'
import { toast } from 'react-toastify'



const ContCard = (props) => {
  const [isopen, setIsopen] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [Luser, setLuser] = useState();
  const [isIOS, setIsIOS] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent));

  const navigate = useNavigate();
  const firebase = useFirebase();
  const handelNav = (id) => {
    navigate(`./contestDetails/${id}`);



  }
  const handelDiv1 = () => {


    if (!firebase.user) {
      toast.error("Login First");
      return;
    }


    firebase.handleAuthStateChange();

    if (firebase.isVerified) {

      setTimeout(() => {

        setIsopen(true);
      }, 300)
    } else {
      firebase.setIsWarn(true)


    }

  }
  useEffect(() => {
    const userJson = localStorage.getItem('userL');
    const user = JSON.parse(userJson);
    setLuser(user);
    console.log("here", user, "luse")

  }, [])







  return (
    <div className='card-main-con'><section class="container">

      <div class="card-container">
        {
          isopen ? (
            <div className="open-con"  >
              <GetContestDetails
                setIsopen={setIsopen}
                name={props.data.name}
                price={props.data.price}
                isAdded={isAdded}
                setIsAdded={setIsAdded}
                LuserID={Luser.uid}
                LuserName={Luser.displayName}
                LuserEmail={Luser.email}
                id={props.data.id}
                isIOS={isIOS}

              />

            </div>
          ) : ("")
        }

        <div class="card-content">
          <div class="card-title">
            <span class="title">{props.data.name}</span>
          </div>
          <div class="card-body">
            <img src={props.data.imgUrl} className='cont-img'>
            </img>

          </div>

          {props.data.id == 5 ? ((
            <div className={isIOS ? "ios-btn-con" : "glowing-button-wrap"} >




              <button className={isIOS ? "ios-btn2" : "glowing-button5"} id='gb2' onClick={() => { window.open('https://techminds.devfolio.co/', '_blank'); }}
              > DevFolio </button>










              <button className={isIOS ? "ios-btn" : "glowing-button3"} onClick={() => handelNav(props.data.id)}>
                More
              </button>

            </div>)) : (

            <div className={isIOS ? "ios-btn-con" : "glowing-button-wrap"}>

              {
                !isAdded ? (
                  <button className={isIOS ? "ios-btn2" : "glowing-button5"} id='gb2' 
                  onClick={handelDiv1}
                  // onClick={()=>toast.error("Entry is closed")}
                  >
                    {props.data.id == 1 ? (<p>Closed</p>) : (<p>Closed</p>)}





                  </button>
                  
                ) : (
                  <button className={isIOS ? "ios-btn" : "glowing-button3"} id='gb2' onClick={handelDiv1}>

                    Remove



                  </button>)
              }






              <button className={isIOS ? "ios-btn" : "glowing-button3"} onClick={() => handelNav(props.data.id)}>
                More
              </button>

            </div>
          )}




        </div>
      </div>
    </section></div>
  )
}

export default ContCard