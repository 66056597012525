import React from 'react';
import './ShippingPolicy.css'; // Import CSS file

const ShippingPolicy = () => {
  return (
    <div className="shipping-policy">
      <h2>Shipping Policy</h2>
      <p>
        At AIEC, we don't ship physical products. Instead, we provide services to our customers(students).
      </p>
     
    </div>
  );
};

export default ShippingPolicy;
