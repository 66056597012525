import React from 'react';
import './privacyPolicy.css'; // Import your CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h2>Privacy Policy</h2>
      <p>
        At AIEC, we value your privacy and are committed
        to protecting your personal information. This Privacy Policy outlines
        how we collect, use, and safeguard your data when you visit our website
        or participate in our events.
      </p>
      <h3>Information We Collect</h3>
      <p>
        When you visit our website or register for events, we may collect the
        following types of personal information:
      </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Student ID (if applicable)</li>
        <li>Contact information</li>
        <li>Payment details (if applicable)</li>
        <li>Student education details (if applicable)</li>
      </ul>
      <h3>How We Use Your Information</h3>
      <p>
        We may use the information we collect for the following purposes:
      </p>
      <ul>
        <li>To register you for events and keep you updated on event details</li>
        <li>To process payments for paid events</li>
        <li>
          To communicate with you about upcoming events, news, and promotions
        </li>
        <li>To improve our website and services</li>
        <li>To comply with legal requirements</li>
      </ul>
      <h3>Data Security</h3>
      <p>
        We take appropriate measures to protect your personal information from
        unauthorized access, alteration, disclosure, or destruction. However,
        please note that no method of transmission over the internet or
        electronic storage is 100% secure, and we cannot guarantee absolute
        security.
      </p>
      <h3>Third-Party Disclosure</h3>
      <p>
        We do not sell, trade, or otherwise transfer your personal information
        to third parties without your consent, except as required by law or to
        fulfill our services (e.g., processing payments).
      </p>
      <h3>Cookies</h3>
      <p>
        Our website may use cookies to enhance your browsing experience. You
        can choose to disable cookies in your browser settings, but please note
        that some features of the website may not function properly as a
        result.
      </p>
      <h3>Links to Third-Party Sites</h3>
      <p>
        Our website may contain links to third-party websites, which have their
        own privacy policies. We are not responsible for the content or privacy
        practices of these websites.
      </p>
      <h3>Changes to This Policy</h3>
      <p>
        We reserve the right to update or change this Privacy Policy at any
        time. Any changes will be posted on this page with an updated revision
        date.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
