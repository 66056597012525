import React from 'react'
import "./left.css"
import { LiaWindowCloseSolid } from "react-icons/lia";
import LeftBox from './LeftBox';
import { useFirebase } from '../../backend/Firebase';

const Left = (props) => {
    const firebase = useFirebase();
    return (
        <div className='l-con'>
            <LiaWindowCloseSolid onClick={() => props.setIsLeft(false)} className='l-close' />
            <div className='l-head'>
                <h2>

                    AIEC
                </h2>

            </div>
            <div className='l-main'>
                <LeftBox data={firebase.buyContest} />


            </div>
            <div className='p2p' onClick={() => 
            {
                setTimeout(()=>{

                    firebase.handlePayment()
                },350)

            }}>

                <button class="glowing-button2 pay-btn" id='pay-btn' >
                    Proced To Payment
                </button>
            </div>
        </div>
    )
}

export default Left