import React, { useEffect, useState } from 'react'
import "./home.css"
import Nav from '../comp/Nav'
import Intro from '../comp/Intro'
import ScrollingText from '../comp/ScrollingText'
import Back from '../comp/Back'

import Event from '../comp/contest/contest'
import Map from '../comp/Map'

import { useFirebase } from '../backend/Firebase'
import { CiShoppingCart } from "react-icons/ci";
import Left from '../comp/Left/Left'
import Foot from '../comp/Foot'
import Right from '../comp/right/Right'
import Sponsor from '../comp/sponser/Sponsor'
import Hurry from '../comp/Hurry'
import Gimples from '../comp/Gimples'

const HomePage = (props) => {

    const [user, setUser] = useState(null);
    const [intro, setIntro] = useState(true);
 
    const [isLeft, setIsLeft] = useState(false);
    const [isRight, setIsRight] = useState(false);
    const [isHurry, setIsHurry] = useState(false);
    const [isIOS, setIsIOS] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent));



    const firebase = useFirebase();
    const [contestCount, setContestCount] = useState(firebase.buyContest.length);
  


    useEffect(() => {
        setTimeout(() => {
            setIntro(false);
            props.setFplay(false);
        }, 8000)

    })
    useEffect(() => {
        setContestCount(firebase.buyContest.length);


    }, [firebase.buyContest])

    console.log("user in l hp", user)
    useEffect(() => {
        const handleBeforeUnload = () => {
            window.scrollTo(0, 0);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // Fetch user from local storage
        const storedUser = localStorage.getItem('userL');

        // Parse the stored user if it exists

        const parsedUser = storedUser ? JSON.parse(storedUser) : null;

        // Set the user in the component state
        setUser(parsedUser);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsHurry(true);



        }, 9000)

    }, []);




    const [cursorX, setCursorX] = useState(0);
    const [cursorY, setCursorY] = useState(0);
    const [deviceType, setDeviceType] = useState('');
    const [isClicking, setIsClicking] = useState(false);
    const [buttonHovered, setButtonHovered] = useState(false);



    const isTouchDevice = () => {
        try {
            document.createEvent('TouchEvent');
            setDeviceType('touch');
            return true;
        } catch (e) {
            setDeviceType('mouse');
            return false;
        }
    };

    const move = (e) => {
        const touchEvent = e.touches ? e.touches[0] : null;
        const x = !isTouchDevice() ? e.clientX : touchEvent?.clientX || 0;
        const y = !isTouchDevice() ? e.clientY : touchEvent?.clientY || 0;

        setCursorX(x);
        setCursorY(y);

        // Set the cursor border's position directly
        const cursorBorder = document.getElementById('cursor-border');
        if (cursorBorder) {
            cursorBorder.style.left = `${x}px`;
            cursorBorder.style.top = `${y}px`;
        }
    };

    const handleMouseDown = () => {
        setIsClicking(true);
    };

    const handleMouseUp = () => {
        setIsClicking(false);
    };

   

    useEffect(() => {
        document.addEventListener('mousemove', move);
        document.addEventListener('touchmove', move);
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', move);
            document.removeEventListener('touchmove', move);
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);








    return (
        <div className='wrapper'>



            {
                intro && props.Fplay ? (<Intro />) : (
                    <div>
                          <div>
                            <Gimples></Gimples>
                        </div>a


                    
                        <div className='wheel' onClick={() => setIsLeft(true)}>

                            <CiShoppingCart />
                            <p className='h-cout'>

                                {contestCount}
                            </p>
                        </div>
                        {!isIOS?(  <Back></Back>):("")}

                      

                        <Nav setIsRight={setIsRight} />
                        <div className='con'>

                            <ScrollingText />
                            <Sponsor/>

                            <Event
                                isLeft={isLeft}
                                setIsLeft={setIsLeft}
                            ></Event>
                            
                            <Foot />




                        </div>
                      

                        <div className={isLeft ? 'leftbar-yes leftbar' : 'leftbar-no leftbar'} >
                            <Left
                                isLeft={isLeft}
                                setIsLeft={setIsLeft}


                            />

                        </div>
                        <div className={isRight ? 'rightbar-yes rightbar' : 'rightbar-no rightbar'}  >
                            <Right
                                isRight={isRight}
                                setIsRight={setIsRight}
                            />


                        </div>
                        <div>
                            {
                                isHurry?(  <Hurry setIsHurry={setIsHurry}/>):("")
                            }
                          


                        </div>

                        {
                            firebase.isWarn ? (
                                <div className='mail-warn'>
                                    Email is not Verified!
                                    <div className='mail-warn-btn-con'>
                                        <button className='mail-warn-btn bc' onClick={() => firebase.setIsWarn(false)}>Close</button>
                                        <button className='mail-warn-btn' onClick={() => firebase.resendVerificationEmail(firebase.user)}>Resend</button>
                                    </div>
                                    <p className='rr-txt'>Reload Required After Verify*</p>
                                </div>
                            ) : null
                        }




                    </div>
                )
            }


            <div>
                <style>
                    {`
        * {
            margin: 0;
            cursor: none;
        }
 
 
        #cursor {
            position: fixed;
            background-color: ${isClicking ? 'white' : 'white'};
            height: 10px;
            width: 10px;
            border-radius: 0%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            z-index:1000000;
            transition: background-color 0.2s ease;
        }
 
        #cursor-border {
            position: fixed;
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: 3px solid ${buttonHovered ? '#48abe0' : '#48abe0'};
            border-radius: 0%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            transition: all 0.1s ease-out;
            z-index:1000000;
        }

     
      `}
                </style>
                <div
                    id="cursor"
                    style={{ left: `${cursorX}px`, top: `${cursorY}px` }}
                ></div>
                <div id="cursor-border"></div>


            </div>













        </div>
    )
}

export default HomePage