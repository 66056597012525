import React  from 'react';
import { useFirebase } from '../../backend/Firebase';
import "./leftBox.css"

const YourComponent = () => {
    const firebase = useFirebase();


    // Check if firebase object exists and has the buyContest property
    if (!firebase || !firebase.buyContest) {
        return <p>No contest data available</p>;
    }

    const buyContest = firebase.buyContest;

    // Calculate total sum of prices
    const totalSum = buyContest.reduce((sum, item) => {
        const contestData = item[Object.keys(item)[0]]; // Extract contest data object
        const price = parseFloat(contestData.price);
        firebase.setAmt(sum + price) // Convert price to float
        return sum + price;
    }, 0);


    return (
        <div>
            <h2>Cart</h2>
            <ul className='l-ul'>
                {buyContest.map((item, index) => {
                    const contestName = Object.keys(item)[0];
                    const contestData = item[contestName];
                    const price = contestData.price;

                    return (
                        <div key={index} className='l-li'>
                            <p className='l-cn'> {contestName}</p>
                             <p className='l-p'>RS -/  {price}</p>
                        </div>
                    );
                })}
            </ul>
            <p className='l-tp' >Total Price <p>Rs-/ {totalSum}</p>  </p>
        </div>
    );
};

export default YourComponent;
