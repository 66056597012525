import React from 'react'
import "./guest.css"
import ak from "./akash.jpg"
import Back from '../Back'
import { CiLinkedin } from "react-icons/ci";

const Guest = () => {
  return (
    <div className='g-wrap'>
      <Back></Back>

      <h2 className='g-head ev-head'>
      Guest of Honour

      </h2>
      <div className='g-con'>
    
        <div className='g-card'>

          <div className='g-img-con'>
            <img className='g-img' src={ak}>
            </img>

          </div>
          <div className='card-bottom'>
            <div className='g-name '>Akash Takyar</div>
            <div className='g-social'>
              <a href='http://linkedin.com/in/akashtakyar'>

            <CiLinkedin className='g-icon'/>
              </a>

            </div>


          </div>

        </div>
        
       

      </div>
    </div>
  )
}

export default Guest