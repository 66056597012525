import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ContestDetail.css';
import { useFirebase } from '../backend/Firebase';
import Foot from "../comp/Foot"
import Nav from "../comp/Nav"
import Glitch from '../comp/Glitch';


const ContestDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const firebase = useFirebase();
  const [isGame, setIsGame] = useState(false);

  const handleFetch = async (id) => {
    try {
      const docData = await firebase.fetchDataforDetails(id);
      console.log("Document data:", docData);
      setData(docData); // Assuming you're setting the whole document data object
    } catch (error) {
      console.error("Error fetching document:", error.message);
    }
  };

  useEffect(() => {
    if (firebase.user) { // Check if firebase.user is not null
      handleFetch(id);
    }
  }, [firebase.user, id]);
  useEffect(() => {
    if (firebase.user) { // Check if firebase.user is not null
      handleFetch(id);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (data?.rules[0] == "It is a 4 player team tournament. A 4 man - starter roster.") {
      setIsGame(true);
    }
  }, []);

  return (
    <div className='d-wrap'>
      {/* <Nav/> */}
      <div className='d-con'>

      <div className='d-ban-con'>
          <img src={data ? data.ban : ""} className="d-img" alt={data ? data.name : ""} />
        </div>

        <h2 className='d-name'>
          {data ? data.name : ""}
        </h2>

        <div className='d-img-con box'>
          <img src={data ? data.kurl : ""} className="d-img" alt={data ? data.name : ""} />
        </div>
          

        {data && (
          <div className='d-main-data'>
            <h2 className='c-heading'>Contest Details</h2>

            <ol>
              <div>
                {data.description && <li className='c-list'>{data.description}</li>}
                {data.prize && <li className='c-list'>{data.prize}</li>}
                {data.price && <li className='c-list'>Registration Fee : {data.price}</li>}
                {data.prize && <li className='c-list'>Winning Prize : {data.prize}</li>}
                {data.theme && <li className='c-list'>{data.theme}</li>}
                {data.team && <li className='c-list'>{data.team}</li>}
                {data.time && <li className='c-list'>{data.time}</li>}
              </div>
            </ol>
            <div>

              <ol>
                {data.prerequisite && Array.isArray(data.prerequisite) && (
                  <div>
                    <h2 className='c-heading'>Prerequisite</h2>

                    {data.prerequisite.map((item, index) => (
                      <li className='c-list' key={index}>{item}</li>
                    ))}

                  </div>
                )}
              </ol>
            </div>

            <div>

              <ul>
                {data.theme && Array.isArray(data.theme) && (
                  <div>
                    <h2 className='c-heading'>Themes</h2>
                    <ol>
                      {data.theme?.map((item, index) => (
                        <li className='c-list' key={index}>{item}</li>
                      ))}
                    </ol>
                  </div>
                )}
              </ul>
            </div>
            <p>{data.structure ? data.structure : ""}</p>

            <div  >
              {
                isGame ? (<h2 className='c-heading' >Rules and Regulation for BGMI Game</h2>) : (<h2 className='c-heading' >Rules</h2>)
              }

              <div>
                {data.rules && Array.isArray(data.rules) && (
                  <div>

                    <ol>
                      {data.rules?.map((item, index) => (
                        <li className='c-list' key={index}>{item}</li>
                      ))}
                    </ol>
                  </div>
                )}
              </div>
              <div>
                {data.points && Array.isArray(data.points) && (
                  <div>
                    <h2 className='c-heading'>Point system </h2>

                    <ol>
                      {data.points?.map((item, index) => (
                        <li className='c-list' key={index}>{item}</li>
                      ))}
                    </ol>
                  </div>
                )}
              </div>
            </div>


            <div  >
              <div>
                {data.rules2 && Array.isArray(data.rules2) && (
                  <div>
                    <h2 className='c-heading' >Rules and Regulation for Tekken 7 Game </h2>

                    <ol>
                      {data.rules2?.map((item, index) => (
                        <li className='c-list' key={index}>{item}</li>
                      ))}
                    </ol>
                  </div>
                )}
              </div>
            </div>

            <div  >
              <div>
                {data.cor && Array.isArray(data.cor) && (
                  <div>
                    <h2 className='c-heading' >Cordinator </h2>

                    <ol>
                      {data.cor?.map((item, index) => (
                        <li className='c-list' key={index}>{item}</li>
                      ))}
                    </ol>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}



        <div className='d-bot'></div>
      </div>
      {/* <Foot/> */}
    </div>
  );
};

export default ContestDetail;
