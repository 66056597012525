import React from 'react'
import "./map.css"

const Map = () => {
  return (
    <div className='map-con'>
        <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.640291914361!2d77.09936552549996!3d28.610566075676708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b5567911807%3A0x996e86fe24144daf!2sInstitute%20of%20Information%20Technology%20and%20Management%20(IITM)!5e0!3m2!1sen!2sin!4v1709567127841!5m2!1sen!2sin"  referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Map