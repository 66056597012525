import React, { useEffect, useState } from 'react';
import "./right.css"
import { LiaWindowCloseSolid } from "react-icons/lia";
import { useFirebase } from '../../backend/Firebase';

const Right = (props) => {
  const [data, setData] = useState([]);
  const firebase = useFirebase();

  const handleFetch = async (docName) => {
    try {
      const docData = await firebase.fetchDocumentDetails(docName);
      console.log("Document data:", docData);
      setData(docData.contests); // Assuming contests is the array inside the received object
    } catch (error) {
      console.error("Error fetching document:", error.message);
    }
  };
  useEffect(() => {
    if (firebase.user) { // Check if firebase.user is not null
      handleFetch(firebase.user.email);
    }
  }, [firebase.user]);

  const url = firebase.user?.photoURL || 'https://firebasestorage.googleapis.com/v0/b/aiec-c3a56.appspot.com/o/img%2Fastro.jpg?alt=media&token=bac47a59-9197-40c1-9b65-f06c37208b6c'

  const name = firebase.user?.displayName || "NA"
  const email = firebase.user?.email || "NA"

  return (
    <div>
      <div className='r-con'>
        <LiaWindowCloseSolid onClick={() => props.setIsRight(false)} className='r-close' />

        <div className='r-pro-con'>
          <img className='r-pro-img' src={url} alt="User Profile"></img>
          <p className='r-pro-txt'>{name}</p>
          <p className='r-pro-txt'>{email}</p>
        </div>

        <p className='r-head'>Purchased Events</p>
        {data.length > 0 && (
          <div className='r-his'>
            {data.map((contest, index) => (
              <p key={index}>
                <p className='r-cont-txt'>{Object.keys(contest)[0]}</p>
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Right;
