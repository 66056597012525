import React from 'react';
import './ContactUs.css'; // Import your CSS file for styling
import Map from '../comp/Map';

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns, please feel free to contact us
        using the information below.
      </p>
      <div className="contact-info">
        <p>Email: <a href="mailto:a.i.experiencecenter.iitm@gmail.com">a.i.experiencecenter.iitm@gmail.com</a></p>
        <p>Call Us on </p>
        <p> SACHINE SONI (8377927727)</p>
        <p>HARSHIT NARANG (9717461045)</p>
        <p>SHREE STUTI (9354212959)</p>
        <p>KHUSHI SIHAG (8851410476)</p>
        <br/>
        If money has been debited from your account for an event registration, but the event is not showing up in your profile, please contact vermabhavi7890@gmail.com only for Payment issue. To report the issue, you need to provide a screenshot, your team name, your name, and your phone number for communication.
        {/* You can add more contact information such as phone number, address, etc. */}
        <br/>
        <br/>
        <p>
        D-29, Janakpuri Institutional Area, Janakpuri, New Delhi, Delhi 110058
        </p>

      </div>
      <Map></Map>
    </div>
  );
};

export default ContactUs;
