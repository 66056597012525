import React from 'react';
import './RefundPolicy.css'; // Import your CSS file for styling

const RefundPolicy = () => {
  return (
    <div className="refund-policy-container">
      <h2>Refund Policy</h2>
      <p>
        We do not offer refunds for any purchases made through our website or
        for participation in our events. All sales are final.
      </p>
      <p>
        If you have any questions or concerns about our refund policy, please
        contact us at <a href="mailto:a.i.experiencecenter.iitm@gmail.com">a.i.experiencecenter.iitm@gmail.com</a>.
      </p>
    </div>
  );
};

export default RefundPolicy;
